import type { specialMfoRoutes, whiteLabelDeniedListRoutes } from '@src/server/routes/constants';
import {
    appWhiteLabelDeniedRoute,
    pilotKZPageRoute,
    specOffersLandingPageRouteBistrodengi,
    specOffersLandingPageRouteTurbozaim,
    specOffersLandingPageRouteWebbankirWb1,
    specOffersLandingPageRouteWebbankirWb2,
    webWhiteLabelDeniedRoute,
} from '@src/server/routes/constants';

type BasePath = typeof specialMfoRoutes[number] & typeof whiteLabelDeniedListRoutes;

export const DEFAULT_AFF_ID_VALUE = '2';

export const affIdByBasePath: Record<BasePath, string> = {
    [specOffersLandingPageRouteWebbankirWb1]: '6184',
    [specOffersLandingPageRouteWebbankirWb2]: '6185',
    [specOffersLandingPageRouteBistrodengi]: '6186',
    [specOffersLandingPageRouteTurbozaim]: '6187',
    [webWhiteLabelDeniedRoute]: '12181',
    [appWhiteLabelDeniedRoute]: '12181',
    [pilotKZPageRoute]: '12024',
};
